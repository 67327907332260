import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import dictionary from "../../dictionary";
import WorkflowCard from "./WorkflowCard";

export default function PostgresFacebookAudienceWorkflow() {
    const data = useStaticQuery(graphql`
    query PostgresFacebookAudienceWorkflowQuery {
      recipe(enabled: {eq: true}, slug: {eq: "postgres-warehouse-facebook-ads-audience"}) {
        content
        description
        name
        total_steps
        slug
        steps {
          object
        }
      }
    }
  `)

    const recipe = data.recipe;

    return (
        <WorkflowCard recipe={recipe} />
    )
}